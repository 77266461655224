/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { GatsbyBrowser } from 'gatsby'
import React from 'react'

import { CookieContextProvider } from './src/modules/shared/cookies/CookieContext'
import { getParamsUrl } from './src/services/utils/paramsUrl/paramsUrl'
import { initAmplitude } from './src/modules/shared/amplitude'
import { setTestABCookie } from './src/services/utils/setTestABCookie'
import { setUtm } from './src/services/sem/setUtm'
import './src/assets/styles/styles.scss'

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  const itemsParamsUrl = getParamsUrl()
  setTestABCookie('12415')
  setTestABCookie('16323')
  initAmplitude()
  itemsParamsUrl && setUtm({ itemsParamsUrl })
}

export { wrapPageElement } from './gatsby-shared'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }): React.JSX.Element => {
  return <CookieContextProvider>{element}</CookieContextProvider>
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = () => {
  if (typeof window !== 'undefined') {
    if (window.history.length === 1 || window.history.length === 2) {
      window.scrollTo(0, 0)
      return false
    }
  }
  return true
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (location.pathname !== '/contact-us/') {
    document.getElementById('fc_frame')?.classList.add('hide')
  } else {
    document.getElementById('fc_frame')?.classList.remove('hide')
  }
}
